.user-input {
  width: 100%;
  padding: 0.5% 0% 0% 0%;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  text-align: center;
  /* margin-bottom: 10px; */
  /* margin-bottom: 20px; */
}

.input-boxes {
  height: 60px;
  width: 60px;
  margin-bottom: 4px;
  margin-left: 4px;
  border: solid 1px;
  outline: none;
  resize: none;
  text-align: center;
  font-size: x-large;
  text-transform: uppercase;
  color: black;
  border-color: lightgrey;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.input-boxes-correct {
  border: solid 1px;
  height: 60px;
  width: 60px;
  margin-bottom: 4px;
  margin-left: 4px;
  resize: none;
  text-align: center;
  font-size: x-large;
  text-transform: uppercase;
  color: black;
  border-color: lightgrey;
  background-color: white;
  animation: jump 1s ease;
  animation-fill-mode: forwards;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.input-boxes-correct-no-anim {
  border: solid 1px;
  height: 60px;
  width: 60px;
  margin-bottom: 4px;
  margin-left: 4px;
  resize: none;
  text-align: center;
  font-size: x-large;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border-color: lightgrey;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.input-boxes-includes {
  border: solid 1px;
  height: 60px;
  width: 60px;
  margin-bottom: 4px;
  margin-left: 4px;
  resize: none;
  text-align: center;
  font-size: x-large;
  text-transform: uppercase;
  color: black;
  border-color: lightgrey;
  background-color: white;
  animation: shakeYellow 0.82s cubic-bezier(.36, .07, .19, .97) both;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.input-boxes-includes-no-anim {
  border: solid 1px;
  height: 60px;
  width: 60px;
  margin-bottom: 4px;
  margin-left: 4px;
  resize: none;
  text-align: center;
  font-size: x-large;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  border-color: lightgrey;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.input-boxes-incorrect {
  border: solid 1px;
  height: 60px;
  width: 60px;
  margin-bottom: 4px;
  margin-left: 4px;
  resize: none;
  text-align: center;
  font-size: x-large;
  text-transform: uppercase;
  border-color: lightgrey;
  background-color: white;
  color: black;
  animation: shakeRed 0.82s cubic-bezier(.36, .07, .19, .97) both;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.input-boxes-incorrect-no-anim {
  border: solid 1px;
  height: 60px;
  width: 60px;
  margin-bottom: 4px;
  margin-left: 4px;
  resize: none;
  text-align: center;
  font-size: x-large;
  text-transform: uppercase;
  background-color: white;
  color: black;
  border-color: lightgrey;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

@keyframes shakeRed {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shakeYellow {
  from {
    background-color: white;
    color: black;
  }

  to {
    background-color: gray;
    color: white;
  }

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes correctGreen {
  from {
    background-color: white;
    color: black;
  }

  to {
    background-color: black;
    color: white;
  }
}

@keyframes fadeYellow {
  from {
    background-color: white;
    color: black;
  }

  to {
    background-color: gray;
    color: white;
  }
}

@keyframes jump {
  from {
    background-color: white;
    color: black;
  }

  to {
    background-color: black;
    color: white;
  }

  0% {
    transform: translate(0%, 0%) scale(1, 1);
  }

  50% {
    transform: translate(0%, -100%) scale(1, 1);
  }

  55% {
    transform: translate(0%, -100%) rotate(15deg);
  }

  60% {
    transform: translate(0%, -100%) rotate(-15deg);
  }

  65% {
    transform: translate(0%, -100%) rotate(15deg);
  }

  70% {
    transform: translate(0%, -100%) rotate(-15deg);
  }

  100% {
    transform: translate(0%, 0%) scale(1, 1);
  }
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }

  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

@media only screen and (max-width: 500px) {
  .user-input {
    width: 100%;
    margin-top: 12px;
  }

  .input-boxes {
    height: 45px;
    width: 45px;
    margin-bottom: 4px;
    margin-left: 4px;
    border: solid 1px;
    outline: none;
    resize: none;
    text-align: center;
    font-size: large;
    text-transform: uppercase;
    color: black;
    border-color: lightgrey;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-correct {
    border: solid 1px;
    height: 45px;
    width: 45px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: large;
    text-transform: uppercase;
    color: black;
    border-color: lightgrey;
    background-color: white;
    animation: correctGreen 2s ease;
    animation-fill-mode: forwards;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-correct-no-anim {
    border: solid 1px;
    height: 45px;
    width: 45px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: large;
    text-transform: uppercase;
    color: white;
    background-color: black;
    border-color: lightgrey;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-includes {
    border: solid 1px;
    height: 45px;
    width: 45px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: large;
    text-transform: uppercase;
    color: black;
    border-color: lightgrey;
    background-color: white;
    animation: fadeYellow 2s ease;
    animation-fill-mode: forwards;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-includes-no-anim {
    border: solid 1px;
    height: 45px;
    width: 45px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: large;
    text-transform: uppercase;
    background-color: gray;
    color: white;
    border-color: lightgrey;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-incorrect {
    border: solid 1px;
    height: 45px;
    width: 45px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: large;
    text-transform: uppercase;
    border-color: lightgrey;
    background-color: white;
    color: black;
    animation: none;
    animation-fill-mode: forwards;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-incorrect-no-anim {
    border: solid 1px;
    height: 45px;
    width: 45px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: large;
    text-transform: uppercase;
    background-color: white;
    color: black;
    border-color: lightgrey;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }
}

@media only screen and (min-width: 501px) and (max-width: 850px) {
  .user-input {
    width: 100%;
    margin-top: 7px;
  }

  .input-boxes {
    height: 55px;
    width: 55px;
    margin-bottom: 4px;
    margin-left: 4px;
    border: solid 1px;
    outline: none;
    resize: none;
    text-align: center;
    font-size: x-large;
    text-transform: uppercase;
    color: black;
    border-color: lightgrey;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-correct {
    border: solid 1px;
    height: 55px;
    width: 55px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: x-large;
    text-transform: uppercase;
    color: black;
    border-color: lightgrey;
    background-color: white;
    animation: jump 1s ease;
    animation-fill-mode: forwards;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-correct-no-anim {
    border: solid 1px;
    height: 55px;
    width: 55px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: x-large;
    text-transform: uppercase;
    color: white;
    background-color: black;
    border-color: lightgrey;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-includes {
    border: solid 1px;
    height: 55px;
    width: 55px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: x-large;
    text-transform: uppercase;
    color: black;
    border-color: lightgrey;
    background-color: white;
    animation: shakeYellow 0.82s cubic-bezier(.36, .07, .19, .97) both;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-includes-no-anim {
    border: solid 1px;
    height: 55px;
    width: 55px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: x-large;
    text-transform: uppercase;
    background-color: gray;
    color: white;
    border-color: lightgrey;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-incorrect {
    border: solid 1px;
    height: 55px;
    width: 55px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: x-large;
    text-transform: uppercase;
    border-color: lightgrey;
    background-color: white;
    color: black;
    animation: shakeRed 0.82s cubic-bezier(.36, .07, .19, .97) both;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .input-boxes-incorrect-no-anim {
    border: solid 1px;
    height: 55px;
    width: 55px;
    margin-bottom: 4px;
    margin-left: 4px;
    resize: none;
    text-align: center;
    font-size: x-large;
    text-transform: uppercase;
    background-color: white;
    color: black;
    border-color: lightgrey;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }
}