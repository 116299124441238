.movie-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin-bottom: 30px;
}

.poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  /* border: 1px solid;
  border-color: white; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

/* Flipper Code */

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
}

.flip-container,
.front,
.back {
  height: clamp(350px, 30vw, 30vw);
  width: clamp(350px, 930px, 50vw);
  /* aspect-ratio: 16 / 9; */
  /* width: clamp(350px, 950px, 55vw); */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

/* flip speed goes here */
.flipper {
  /* transition: 1.5s; */
  transition: 2s;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transform-style: preserve-3d;
  width: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: 100%;
  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* front pane, placed above back */
.front {
  /* border: solid 1px;
  border-color: lightgrey; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: white;
  height: clamp(350px, 30vw, 30vw);
  width: clamp(350px, 930px, 50vw);
    /* aspect-ratio: 16 / 9; */
  /* height: 55vh; */
  /* width: clamp(350px, 950px, 55vw); */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  /* for firefox 31 */
  transform: rotateY(0deg);
  /* border: solid 1px;
  border-color: blue; */
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}

.non-flip {
  /* height: 55vh; */
  height: clamp(350px, 30vw, 30vw);
  width: clamp(350px, 930px, 50vw);
    /* aspect-ratio: 16 / 9; */
  /* width: clamp(350px, 950px, 55vw); */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.flip-container .flipper,
.flip-container .flipper,
.flip-container.flip .flipper {
  transform: rotateY(180deg);
}

/* Fade Code */

.front-transparent {
  position: absolute;
  -webkit-transition: opacity 2.5s ease-in-out;
  -moz-transition: opacity 2.5s ease-in-out;
  -o-transition: opacity 2.5s ease-in-out;
  transition: opacity 2.5s ease-in-out;
  opacity: 0;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: white;
  height: clamp(350px, 30vw, 30vw);
  width: clamp(350px, 930px, 50vw);
    /* aspect-ratio: 16 / 9; */
  /* width: clamp(350px, 950px, 55vw); */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.fade-container {
  position: relative;
  height: clamp(350px, 30vw, 30vw);
  width: clamp(350px, 930px, 50vw);
    /* aspect-ratio: 16 / 9; */
  /* width: clamp(350px, 950px, 55vw); */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.bottom {
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-transition: opacity 2.5s ease-in-out;
  -moz-transition: opacity 2.5s ease-in-out;
  -o-transition: opacity 2.5s ease-in-out;
  transition: opacity 2.5s ease-in-out;
  background-color: white;
  border: 0.5px solid;
  border-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.top {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: 0.5px solid;
  border-color: white;
  -webkit-transition: opacity 2.5s ease-in-out;
  -moz-transition: opacity 2.5s ease-in-out;
  -o-transition: opacity 2.5s ease-in-out;
  transition: opacity 2.5s ease-in-out;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.bottom-fade {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-transition: opacity 2.5s ease-in-out;
  -moz-transition: opacity 2.5s ease-in-out;
  -o-transition: opacity 2.5s ease-in-out;
  transition: opacity 2.5s ease-in-out;
  background-color: white;
  border: 0.5px solid;
  border-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media only screen and (max-width: 1100px) {
  /* Fade Code */

  .front-transparent {
    position: absolute;
    -webkit-transition: opacity 2.5s ease-in-out;
    -moz-transition: opacity 2.5s ease-in-out;
    -o-transition: opacity 2.5s ease-in-out;
    transition: opacity 2.5s ease-in-out;
    opacity: 0;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: white;
    height: clamp(350px, 40vw, 40vw);
    width: clamp(350px, 950px, 57vw);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .fade-container {
    position: relative;
    height: clamp(350px, 40vw, 40vw);
    width: clamp(350px, 950px, 57vw);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }

  .bottom {
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-transition: opacity 2.5s ease-in-out;
    -moz-transition: opacity 2.5s ease-in-out;
    -o-transition: opacity 2.5s ease-in-out;
    transition: opacity 2.5s ease-in-out;
    background-color: white;
    border: 0.5px solid;
    border-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .top {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 0.5px solid;
    border-color: white;
    -webkit-transition: opacity 2.5s ease-in-out;
    -moz-transition: opacity 2.5s ease-in-out;
    -o-transition: opacity 2.5s ease-in-out;
    transition: opacity 2.5s ease-in-out;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .bottom-fade {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-transition: opacity 2.5s ease-in-out;
    -moz-transition: opacity 2.5s ease-in-out;
    -o-transition: opacity 2.5s ease-in-out;
    transition: opacity 2.5s ease-in-out;
    background-color: white;
    border: 0.5px solid;
    border-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  /* Flipper Code */

  .poster {
    height: clamp(350px, 40vw, 40vw);
    width: clamp(350px, 950px, 57vw);
    object-fit: cover;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    /* border: 1px solid;
    border-color: white; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  /* entire container, keeps perspective */
  .flip-container {
    perspective: 1000px;
  }

  .flip-container,
  .front,
  .back {
    height: clamp(350px, 40vw, 40vw);
    width: clamp(350px, 950px, 57vw);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  /* flip speed goes here */
  .flipper {
    /* transition: 1.5s; */
    transition: 2s;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transform-style: preserve-3d;
    width: 100%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: 100%;
    position: relative;
  }

  /* hide back of pane during swap */
  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  /* front pane, placed above back */
  .front {
    /* border: solid 1px;
    border-color: lightgrey; */
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: white;
    height: clamp(350px, 40vw, 40vw);
    width: clamp(350px, 950px, 57vw);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    /* for firefox 31 */
    transform: rotateY(0deg);
    /* border: solid 1px;
    border-color: blue; */
  }

  /* back, initially hidden pane */
  .back {
    transform: rotateY(180deg);
  }

  .non-flip {
    height: clamp(350px, 40vw, 40vw);
    width: clamp(350px, 950px, 57vw);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .flip-container .flipper,
  .flip-container .flipper,
  .flip-container.flip .flipper {
    transform: rotateY(180deg);
  }
}


/* .movie-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin-bottom: 30px;
}

.poster {
  width: 300px;
	height: 450px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.details-list {
  padding: 1% 3% 1% 3%;
  list-style: none;
  text-align: left;
  font-size: larger;
  border: solid 1px;
  border-color: lightgray;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  min-width: 40vw;
}

.unknown-text {
  font-size: 55px;
  margin-top: 170px;
  text-align: center;
}

.flip-container {
	perspective: 1000px;
}
.flip-container, .front, .back {
  width: 300px;
	height: 450px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.flipper {
	transition: 1.5s;
	transform-style: preserve-3d;

	position: relative;
}

.front, .back {
	backface-visibility: hidden;

	position: absolute;
	top: 0;
	left: 0;
}

.front {
	z-index: 2;
  border: solid 1px;
  width: 300px;
	height: 450px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
	transform: rotateY(0deg);
}

.back {
	transform: rotateY(180deg);
}
.non-flip {
  width: 300px;
	height: 450px;
}
.flip-container .flipper, .flip-container .flipper, .flip-container.flip .flipper {
	transform: rotateY(180deg);
} */